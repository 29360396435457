<template>
  <div class="remboursementsConseillerDashboardComponent">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isRemboursementsLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>


    <div class="row">
      <div class="col-12">
        <p> Par défaut, il vous est présenté la liste des demandes de remboursements en cours de validation et non attribuées à un conseiller.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillerDashboardAllRemboursementsNotAttributedState-group" label="Etat de la demande" label-for="conseillerDashboardAllRemboursementsNotAttributedState-input">
          <b-form-select
            v-model="conseillerDashboardAllRemboursementsNotAttributedState"
            id="conseillerDashboardAllRemboursementsNotAttributedState-input"
            :options='notAttributedRemboursementStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterAllRemboursementsNotAttributedByState'
          :disabled="$v.conseillerDashboardAllRemboursementsNotAttributedState.$invalid">
            <v-icon v-if="conseillerDashboardAllRemboursementsNotAttributedByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer les demandes de remboursement <strong>NON ATTRIBUEES </strong>
        </b-button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillerDashboardAllRemboursementsState-group" label="Etat de la demande" label-for="conseillerDashboardAllRemboursementsState-input">
          <b-form-select
            v-model="conseillerDashboardAllRemboursementsState"
            id="conseillerDashboardAllRemboursementsState-input"
            :options='remboursementStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterAllRemboursementsByState'
          :disabled="$v.conseillerDashboardAllRemboursementsState.$invalid">
            <v-icon v-if="conseillerDashboardAllRemboursementsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer <strong>TOUTES</strong> les demandes de remboursement
        </b-button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillerDashboardOwnRemboursementsState-group" label="Etat de la demande" label-for="conseillerDashboardOwnRemboursementsState-input">
          <b-form-select
            v-model="conseillerDashboardOwnRemboursementsState"
            id="conseillerDashboardOwnRemboursementsState-input"
            :options='remboursementStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterOwnRemboursementsByState'
          :disabled="$v.conseillerDashboardOwnRemboursementsState.$invalid">
            <v-icon v-if="conseillerDashboardOwnRemboursementsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer <strong>VOS</strong> demandes de remboursement
        </b-button>
      </div>
    </div>

    <b-form v-on:submit.prevent="getAllRemboursementsByMuna()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="muna-form-group">
            <b-form-input
              id="muna-form-input"
              type="text" maxlength="11"
              v-model.trim.lazy="conseillerDashboardSearchRemboursementsMuna"
              @input="$v.conseillerDashboardSearchRemboursementsMuna.$touch()"
              :state="$v.conseillerDashboardSearchRemboursementsMuna.$dirty ? !$v.conseillerDashboardSearchRemboursementsMuna.$error : null"
              placeholder="Muna du bénéficiaire (XXXXXXXX.XX)">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.conseillerDashboardSearchRemboursementsMuna.$invalid">
              <v-icon v-if="conseillerDashboardRemboursementsIsMunaFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par muna
          </b-button>
        </div>
      </div>
    </b-form>

    <b-form v-on:submit.prevent="getAllRemboursementsByBeneficiaireName()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="name-form-group">
            <b-form-input
              id="name-form-input"
              type="text" maxlength="150"
              v-model.trim.lazy="conseillerDashboardSearchRemboursementsName"
              @input="$v.conseillerDashboardSearchRemboursementsName.$touch()"
              :state="$v.conseillerDashboardSearchRemboursementsName.$dirty ? !$v.conseillerDashboardSearchRemboursementsName.$error : null"
              placeholder="Prénom ou nom du bénéficiaire">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type='submit'
            :disabled="$v.conseillerDashboardSearchRemboursementsName.$invalid">
              <v-icon v-if="conseillerDashboardIsRemboursementsNameFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par prénom ou nom du bénéficiaire
          </b-button>
        </div>
      </div>
    </b-form>

    <b-button
      v-if="attributedRemboursementsList.length > 0"
      block variant="outline-danger"
      @click='attributeRemboursementsToHimself'>
        Vous attributer les demandes de remboursement sélectionnées
    </b-button>

    <b-table
      responsive class="align-middle text-center mt-4"
      headVariant="light"
      :fields="remboursementsRenderFields"
      :items="remboursements"
      >
      <!--@row-clicked="beneficiaireRowClicked"-->
      <template v-slot:cell(actions)="data">
        <b-form-checkbox
          @input="checkRemboursementBox(data.item.id)"
          >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(month)="data">
        {{monthRender(data.item.month)}}
      </template>
      <template v-slot:cell(montant)="data">
        {{data.item.montant}} € <br>
        <small>{{data.item.calculated_montant_total}} € calculé automatiquement </small>
        <small
          v-if="data.item.calculated_montant_frais_sup_total > 0"
        >
          <br>
          Frais sup : {{data.item.calculated_montant_frais_sup_total}} € calculé automatiquement
        </small>
      </template>
      <template v-slot:cell(state)="data">
        {{remboursementStateRender(data.item.state)}}
      </template>
      <template v-slot:cell(state_changed_date)="data">
        {{$dayjs(data.item.state_changed_date).format('DD/MM/YYYY')}}
      </template>
      <template v-slot:cell(first_ask_validation_date)="data">
        <span v-if="data.item.first_ask_validation_date">
          {{$dayjs(data.item.first_ask_validation_date).format('DD/MM/YYYY')}}
        </span>
      </template>
      <template v-slot:cell(go_to_profile)="data">
        <b-button
          variant="outline-danger" class="ml-2" size="sm"
          @click="$router.push('/conseiller-dashboard/remboursement/' + data.item['id'])">
          <v-icon name="arrow-right"/>
        </b-button>
      </template>
    </b-table>

    <b-button
      v-if="getAllRemboursementsNotAttributedByStateUrl && conseillerDashboardAllRemboursementsNotAttributedByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllRemboursementsNotAttributedByState(true)'>
        Voir plus
    </b-button>

    <b-button
      v-if="getAllRemboursementsByStateUrl && conseillerDashboardAllRemboursementsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllRemboursementsByState(true)'>
        Voir plus
    </b-button>

    <b-button
      v-if="getOwnRemboursementsByStateUrl && conseillerDashboardOwnRemboursementsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getOwnRemboursementsByState(true)'>
        Voir plus
    </b-button>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import renderMixins from '@/mixins/renderMixins'
import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { isMuna } from '@/validators/validators'

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'remboursementsConseillerDashboardComponent',
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      remboursements: [],
      remboursementsRenderFields: [
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
        { key: "beneficiaire_muna", label: "Muna", tdClass: 'ui-helper-center', sortable: true},
        { key: "beneficiaire_full_name", label: "Nom du bénéficiaire", tdClass: 'ui-helper-center', sortable: true},
        { key: "month", label: "Mois concerné", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "montant", label: "Montant", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center', sortable: true},
        { key: "state_changed_date", label: "Dernier changement d'état", tdClass: 'ui-helper-center', sortable: true},
        { key: "first_ask_validation_date", label: "Date de dépôt", tdClass: 'ui-helper-center', sortable: true},
        { key: "conseiller_full_name", label: "Conseiller attribué", tdClass: 'minWidthTd ui-helper-center', sortable: true },
        { key: "go_to_profile", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isRemboursementsLoading: true,

      // ------------ GET ALL REMBOURSEMENT TO ATTRIBUTE BY STATE-----------
      baseGetAllRemboursementsNotAttributedByStateUrl: apiBaseUrl + `/conseiller-get-all-remboursements-not-attributed-by-state/`,
      getAllRemboursementsNotAttributedByStateUrl: apiBaseUrl + `/conseiller-get-all-remboursements-not-attributed-by-state/`,

      // ------------ GET ALL REMBOURSEMENT BY STATE -----------
      baseGetAllRemboursementsByStateUrlUrl: apiBaseUrl + `/conseiller-get-all-remboursements-by-state/`,
      getAllRemboursementsByStateUrl: apiBaseUrl + `/conseiller-get-all-remboursements-by-state/`,

      // ------------ GET ALL OWN REMBOURSEMENT BY STATE -----------
      baseGetOwnRemboursementsByStateUrl: apiBaseUrl + `/conseiller-get-all-own-remboursements-by-state/`,
      getOwnRemboursementsByStateUrl: apiBaseUrl + `/conseiller-get-all-own-remboursements-by-state/`,

      notAttributedRemboursementStateArrayForm: [
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Validé par le manager, à mettre en paiement', value: '7MVALIDATED' },
      ],

      remboursementStateArrayForm: [
        { text: 'Créé par le bénéficiaire', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Incomplet', value: '3INCOMPLET' },
        { text: 'En doute', value: 'DOUTE' },
        { text: 'Refusé par le conseiller', value: '4CREFUSED' },
        { text: 'Validé par le conseiller', value: '5CVALIDATED' },
        { text: 'Refusé par le manager', value: '6MREFUSED' },
        { text: 'Validé par le manager, à mettre en paiement', value: '7MVALIDATED' },
        { text: 'Mis en paiement', value: '8PAIEMENT' },
      ],

      // ---------- ATTRIBUTE ACCORDS -----------------------
      attributedRemboursementsList: [],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    conseillerDashboardAllRemboursementsNotAttributedState: {
      get () {
        return this.$store.state.conseillerDashboardAllRemboursementsNotAttributedState
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllRemboursementsNotAttributedState', value])
      }
    },
    conseillerDashboardAllRemboursementsNotAttributedByStateFilter: {
      get () {
        return this.$store.state.conseillerDashboardAllRemboursementsNotAttributedByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllRemboursementsNotAttributedByStateFilter', value])
      }
    },
    conseillerDashboardAllRemboursementsState: {
      get () {
        return this.$store.state.conseillerDashboardAllRemboursementsState
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllRemboursementsState', value])
      }
    },
    conseillerDashboardAllRemboursementsByStateFilter: {
      get () {
        return this.$store.state.conseillerDashboardAllRemboursementsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllRemboursementsByStateFilter', value])
      }
    },
    conseillerDashboardOwnRemboursementsState: {
      get () {
        return this.$store.state.conseillerDashboardOwnRemboursementsState
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardOwnRemboursementsState', value])
      }
    },
    conseillerDashboardOwnRemboursementsByStateFilter: {
      get () {
        return this.$store.state.conseillerDashboardOwnRemboursementsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardOwnRemboursementsByStateFilter', value])
      }
    },
    conseillerDashboardSearchRemboursementsMuna: {
      get () {
        return this.$store.state.conseillerDashboardSearchRemboursementsMuna
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardSearchRemboursementsMuna', value])
      }
    },
    conseillerDashboardRemboursementsIsMunaFilter: {
      get () {
        return this.$store.state.conseillerDashboardRemboursementsIsMunaFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardRemboursementsIsMunaFilter', value])
      }
    },
    conseillerDashboardSearchRemboursementsName: {
      get () {
        return this.$store.state.conseillerDashboardSearchRemboursementsName
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardSearchRemboursementsName', value])
      }
    },
    conseillerDashboardIsRemboursementsNameFilter: {
      get () {
        return this.$store.state.conseillerDashboardIsRemboursementsNameFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardIsRemboursementsNameFilter', value])
      }
    },
  },
  created: function() {
    if (this.conseillerDashboardAllRemboursementsByStateFilter && this.conseillerDashboardAllRemboursementsState) {
      this.getAllRemboursementsByState()
    }
    else if (this.conseillerDashboardOwnRemboursementsByStateFilter && this.conseillerDashboardOwnRemboursementsState) {
      this.getOwnRemboursementsByState()
    }
    else if (this.conseillerDashboardRemboursementsIsMunaFilter && isMuna(this.conseillerDashboardSearchRemboursementsMuna) && this.conseillerDashboardSearchRemboursementsMuna != null && this.conseillerDashboardSearchRemboursementsMuna != '') {
      this.getAllRemboursementsByMuna()
    }
    else if (this.conseillerDashboardIsRemboursementsNameFilter && this.conseillerDashboardSearchRemboursementsName != null && this.conseillerDashboardSearchRemboursementsName != '') {
      this.getAllRemboursementsByBeneficiaireName()
    }
    else {
      this.getAllRemboursementsNotAttributedByState()
    }
  },
  validations: {
    conseillerDashboardAllRemboursementsNotAttributedState: {
      required
    },
    conseillerDashboardAllRemboursementsState: {
      required
    },
    conseillerDashboardOwnRemboursementsState: {
      required
    },
    conseillerDashboardSearchRemboursementsMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    conseillerDashboardSearchRemboursementsName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
    }
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllRemboursementsNotAttributedByState (isNext) {
      this.isRemboursementsLoading = true
      var url = this.getAllRemboursementsNotAttributedByStateUrl + this.conseillerDashboardAllRemboursementsNotAttributedState
      if (isNext) {
        url = this.getAllRemboursementsNotAttributedByStateUrl
      }
      apiConseillerConnected.getAllRemboursementsNotAttributedByState(this.token, url)
      .then((result) => {
        this.remboursements = this.remboursements.concat(result.data.results)
        console.log(this.remboursements)
        this.getAllRemboursementsNotAttributedByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllRemboursementsNotAttributedByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.conseillerDashboardAllRemboursementsNotAttributedByStateFilter = true
        this.conseillerDashboardAllRemboursementsByStateFilter = false
        this.conseillerDashboardOwnRemboursementsByStateFilter = false
        this.conseillerDashboardRemboursementsIsMunaFilter = false
        this.conseillerDashboardIsRemboursementsNameFilter = false
      })
    },

    getAllRemboursementsByState (isNext) {
      this.isRemboursementsLoading = true
      var url = this.getAllRemboursementsByStateUrl + this.conseillerDashboardAllRemboursementsState
      if (isNext) {
        url = this.getAllRemboursementsByStateUrl
      }
      apiConseillerConnected.getAllRemboursementsByState(this.token, url)
      .then((result) => {
        this.remboursements = this.remboursements.concat(result.data.results)
        this.getAllRemboursementsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllRemboursementsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.conseillerDashboardAllRemboursementsNotAttributedByStateFilter =false
        this.conseillerDashboardAllRemboursementsByStateFilter = true
        this.conseillerDashboardOwnRemboursementsByStateFilter = false
        this.conseillerDashboardRemboursementsIsMunaFilter = false
        this.conseillerDashboardIsRemboursementsNameFilter = false
      })
    },

    getOwnRemboursementsByState (isNext) {
      this.isRemboursementsLoading = true
      var url = this.getOwnRemboursementsByStateUrl + this.conseillerDashboardOwnRemboursementsState
      if (isNext) {
        url = this.getOwnRemboursementsByStateUrl
      }
      apiConseillerConnected.getOwnRemboursementsByState(this.token, url)
      .then((result) => {
        this.remboursements = this.remboursements.concat(result.data.results)
        this.getOwnRemboursementsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getOwnRemboursementsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.conseillerDashboardAllRemboursementsNotAttributedByStateFilter =false
        this.conseillerDashboardAllRemboursementsByStateFilter = false
        this.conseillerDashboardOwnRemboursementsByStateFilter = true
        this.conseillerDashboardRemboursementsIsMunaFilter = false
        this.conseillerDashboardIsRemboursementsNameFilter = false
      })
    },

    getAllRemboursementsByMuna () {
      this.isRemboursementsLoading = true
      this.remboursements = []
      apiConseillerConnected.getAllRemboursementsByMuna(this.token, this.conseillerDashboardSearchRemboursementsMuna)
      .then((result) => {
        this.remboursements = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllRemboursementsByMuna API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.conseillerDashboardAllRemboursementsNotAttributedByStateFilter =false
        this.conseillerDashboardAllRemboursementsByStateFilter = false
        this.conseillerDashboardOwnRemboursementsByStateFilter = false
        this.conseillerDashboardRemboursementsIsMunaFilter = true
        this.conseillerDashboardIsRemboursementsNameFilter = false
      })
    },

    getAllRemboursementsByBeneficiaireName () {
      this.isRemboursementsLoading = true
      this.remboursements = []
      apiConseillerConnected.getAllRemboursementsByBeneficiaireName(this.token, this.conseillerDashboardSearchRemboursementsName)
      .then((result) => {
        this.remboursements = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllRemboursementsByBeneficiaireName API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
        this.conseillerDashboardAllRemboursementsNotAttributedByStateFilter =false
        this.conseillerDashboardAllRemboursementsByStateFilter = false
        this.conseillerDashboardOwnRemboursementsByStateFilter = false
        this.conseillerDashboardRemboursementsIsMunaFilter = false
        this.conseillerDashboardIsRemboursementsNameFilter = true
      })
    },

    filterAllRemboursementsNotAttributedByState () {
      this.getAllRemboursementsNotAttributedByStateUrl = this.baseGetAllRemboursementsNotAttributedByStateUrl
      this.remboursements = []
      this.getAllRemboursementsNotAttributedByState(false)
    },

    filterAllRemboursementsByState () {
      this.getAllRemboursementsByStateUrl = this.baseGetAllRemboursementsByStateUrlUrl
      this.remboursements = []
      this.getAllRemboursementsByState(false)
    },

    filterOwnRemboursementsByState () {
      this.getOwnRemboursementsByStateUrl = this.baseGetOwnRemboursementsByStateUrl
      this.remboursements = []
      this.getOwnRemboursementsByState(false)
    },

    // ---------------- ATTRIBUTE CONSEILLER ---------------
    checkRemboursementBox (remboursementId) {
      const index = this.attributedRemboursementsList.indexOf(remboursementId)
      if (index === -1){
        this.attributedRemboursementsList.push(remboursementId)
      }
      else {
        this.attributedRemboursementsList.splice(index, 1)
      }
    },
    attributeRemboursementsToHimself () {
      this.isRemboursementsLoading = true
      apiConseillerConnected.attributeRemboursementsToHimself(this.token, this.attributedRemboursementsList)
      .then(() => {
        this.attributedRemboursementsList = []
        if (this.conseillerDashboardAllRemboursementsByStateFilter && this.conseillerDashboardAllRemboursementsState) {
          this.filterAllRemboursementsByState()
        }
        if (this.conseillerDashboardOwnRemboursementsByStateFilter && this.conseillerDashboardOwnRemboursementsState) {
          this.filterOwnRemboursementsByState()
        }
        else if (this.conseillerDashboardRemboursementsIsMunaFilter && isMuna(this.conseillerDashboardSearchRemboursementsMuna) && this.conseillerDashboardSearchRemboursementsMuna != null && this.conseillerDashboardSearchRemboursementsMuna != '') {
          this.getAllRemboursementsByMuna()
        }
        else if (this.conseillerDashboardIsRemboursementsNameFilter && this.conseillerDashboardSearchRemboursementsName != null && this.conseillerDashboardSearchRemboursementsName != '') {
          this.getAllRemboursementsByBeneficiaireName()
        }
        else {
          this.filterAllRemboursementsNotAttributedByState()
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard attributeRemboursementsToHimself API Error : ' + String(error))
      })
      .finally(() => {
        this.isRemboursementsLoading = false
      })
    },
  }
}
</script>
<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
