export default {
  methods: {

    trueFalseRender(state) {
      switch (state) {
        case true:
          return 'Oui'
        case false:
          return 'Non'
      }
      return 'Erreur'
    },

    activiteRender(state) {
      switch (state) {
        case 'ART':
          return 'Artiste'
        case 'TEC':
          return 'Technicien'
      }
      return 'Erreur'
    },

    situationRender(marital_status) {
      switch (marital_status) {
        case 'CELIB':
          return 'Célibataire'
        case 'MAR':
          return 'Marié(e)'
        case 'DIV':
          return 'Divorcé(e)'
        case 'SEP':
          return 'Séparé(e)'
        case 'PACS':
          return 'PACS'
        case 'VIEMAR':
          return 'Vie maritale / Concubinage'
      }

      return 'Erreur'
    },
    formatDateInTable(date) {
      if (date) {
        return this.$dayjs(date).format('D MMM YYYY')
      }
      else {
        return ''
      }
    },

    accordStateRender(state) {
      switch (state) {
        case '1CREATED':
          return 'Créé par le bénéficiaire'
        case '2ONGOING':
          return 'A valider par un conseiller'
        case '3INCOMPLET':
          return 'Incomplet'
        case '3IMPOTINCOMPLET':
          return "Incomplet : avis d'imposition non suffisant"
        case '4CREFUSED':
          return 'Refusé par le conseiller'
        case '5CVALIDATED':
          return 'Validé par le conseiller'
        case '6MREFUSED':
          return 'Refusé par le manager'
        case '7MVALIDATED':
          return 'Validé par le manager'
      }
    },

    remboursementStateRender(state) {
      switch (state) {
        case '1CREATED':
          return 'Créé par le bénéficiaire'
        case '2ONGOING':
          return 'A valider par un conseiller'
        case '3INCOMPLET':
          return 'Incomplet'
        case 'DOUTE':
          return 'En doute, à traiter par un manager'
        case '4CREFUSED':
          return 'Refusé par le conseiller'
        case '5CVALIDATED':
          return 'Validé par le conseiller'
        case '6MREFUSED':
          return 'Refusé par le manager'
        case '7MVALIDATED':
          return 'Validé par le manager'
        case '8PAIEMENT':
          return 'Mis en paiement'
      }
    },

    beneficiaireRemboursementStateRender(state) {
      switch (state) {
        case '1CREATED':
          return 'Créé, à compléter'
        case '2ONGOING':
          return 'En cours de validation'
        case '3INCOMPLET':
          return 'Incomplet, à modifier'
        case 'DOUTE':
          return 'Pris en charge par un conseiller'
        case '4CREFUSED':
          return 'Pris en charge par un conseiller'
        case '5CVALIDATED':
          return 'Pris en charge par un conseiller'
        case '6MREFUSED':
          return 'Refusé'
        case '7MVALIDATED':
          return 'Validé'
        case '8PAIEMENT':
          return 'Mis en paiement'
      }
    },

    monthRender(state) {
      switch (state) {
        case 1:
          return 'Janvier'
        case 2:
          return 'Février'
        case 3:
          return 'Mars'
        case 4:
          return 'Avril'
        case 5:
          return 'Mai'
        case 6:
          return 'Juin'
        case 7:
          return 'Juillet'
        case 8:
          return 'Août'
        case 9:
          return 'Septembre'
        case 10:
          return 'Octobre'
        case 11:
          return 'Novembre'
        case 12:
          return 'Décembre'
      }
    },

    formatThousandSeparatorNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },

    ccnRender(ccn) {
      switch (ccn) {
        case '1PRODAUDIO':
          return '1. Production audiovisuelle'
        case '2PRODCINE':
          return '2. Production cinématographique'
        case '3EDCINE':
          return '3. Edition phonographique'
        case '4PRESTEC':
          return "4. Prestations techniques au service de la création et de l'évènement"
        case '5RADIO':
          return '5. Radiodiffusion'
        case '6SPECSUB':
          return '6. Spectacle vivant subventionné'
        case '7SPECPRIV':
          return '7. Spectacle vivant privé'
        case '8LOIS':
          return "8. Espaces des loisirs, d'attractions et cultures"
        case '9TELE':
          return '9. Télédiffusion'
        case '10ANIM':
          return "10. Production des films d'animation"
        case '11GUSO':
          return "11. Guso"

      }
    },
    modeDeGardeRender(type) {
      switch (type) {
        case 'CRECHE':
          return 'Crèche ou périscolaire'
        case 'GAD_PAJEMPLOI':
          return 'Garde à domicile/Assistante maternelle via PAJEMPLOI'
        case 'GAD_CESU':
          return 'Garde à domicile via CESU'
        case 'GAD_STRUCTURE':
          return "Garde à domicile via une structure de garde d'enfants / Microcrèche"
        case 'PAIR':
          return 'Jeune fille au pair'
      }
    },
    sexRender (sex) {
      switch (sex) {
        case 'H':
          return 'Homme'
        case 'F':
          return 'Femme'
        case 'N':
          return 'Non précisé'
        default:
          return 'Erreur'
      }
      
    }
  }
}
