import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIConseillerConnected {

  createAbattement(token, accord_pk, data) {
    const url = `${apiBaseUrl}/accord/${accord_pk}/abattements`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  createFraisSupplementaire(token, data) {
    const url = `${apiBaseUrl}/frais-supplementaires/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteFraisSupplementaire(token, id) {
    const url = `${apiBaseUrl}/frais-supplementaires/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  getConseillers (token) {
    const url = `${apiBaseUrl}/conseillers`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  deleteAbattement(token, abattement_pk) {
    const url = `${apiBaseUrl}/abattements/${abattement_pk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url, {'headers': headers}
    )
  }
  
  // ------------------- BENEFICIARE ---------------------
  getBeneficiaire (token, beneficiaire_pk) {
    const url = apiBaseUrl + `/conseiller-get-beneficiaire/` + beneficiaire_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  saveMunaMemoMessage (token, beneficiaire_pk, muna, memo_message) {
    const url = apiBaseUrl + `/muna-memo-message/` + beneficiaire_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'muna': muna,
      'memo_message': memo_message,
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  modifyAccord (token, accord_pk, data) {
    const url = apiBaseUrl + `/conseiller-accord-details/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  // ------------------- ACCORDS -------------------------

  getAllAccordsOngoingNotAttributed (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-accords-ongoing-not-attributed/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllAccordsByState (token, url, conseillerId) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-accords-by-state/
    */

    if (conseillerId && !url.includes('conseiller=')) {
      url = url + `?conseiller=${conseillerId}`
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getOwnAccordsByState (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-own-accords-by-state/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllAccordsByMuna (token, muna) {
    const url = apiBaseUrl + `/conseiller-get-all-accords-by-muna/` + muna
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllAccordsByBeneficiaireName (token, search) {
    const url = apiBaseUrl + `/conseiller-get-all-accords-by-beneficiaire-name/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  getAccord (token, accord_pk) {
    const url = apiBaseUrl + `/conseiller-accord-details/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  attributeAccordsToHimself (token, accords_pk_list) {
    const url = apiBaseUrl + `/conseiller-attribute-accords-to-himself/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      accords: accords_pk_list
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  validateAccord (token, accord_pk) {
    const url = apiBaseUrl + `/conseiller-validate-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  refuseAccord (token, accord_pk, refused_message) {
    const url = apiBaseUrl + `/conseiller-refuse-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  incompleteAccord (token, accord_pk, refused_message) {
    const url = apiBaseUrl + `/conseiller-incomplete-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }
  impotsIncompleteAccord (token, accord_pk, refused_message, is_avis_situation_mandatory, is_teleactualisation_mandatory, is_attestation_paiement_mandatory) {
    const url = apiBaseUrl + `/conseiller-impots-incomplete-accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'is_avis_situation_mandatory': is_avis_situation_mandatory,
      'is_teleactualisation_mandatory': is_teleactualisation_mandatory,
      'is_attestation_paiement_mandatory': is_attestation_paiement_mandatory
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  // ------------------ ACCORD DOCUMENT ------------
  createAccordDocument (token, accord_pk, document_type, file) {
    const url = apiBaseUrl + `/conseiller-create-accord-document/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    formData.append('type', document_type);
    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getAllAccordDocuments (token, accord_pk) {
    const url = apiBaseUrl + `/conseiller-get-all-accord-documents/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAccordProtectedDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/conseiller-accord-document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  validateDocument (token, document_pk) {
    const url = apiBaseUrl + `/conseiller-validate-accord-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }

  refuseDocument (token, document_pk) {
    const url = apiBaseUrl + `/conseiller-refuse-accord-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }



  // ------------------- REBMOURSEMENTS -------------------------

  createRemboursementDocument (token, remboursement_pk, document_type, file) {
    const url = apiBaseUrl + `/conseiller-create-remboursement-document/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    formData.append('type', document_type);
    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getAllRemboursementsNotAttributedByState (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-remboursements-ongoing-not-attributed/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllRemboursementsByState (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-remboursements-by-state/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getOwnRemboursementsByState (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : conseiller-get-all-own-remboursements-by-state/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllRemboursementsByMuna (token, muna) {
    const url = apiBaseUrl + `/conseiller-get-all-remboursements-by-muna/` + muna
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllRemboursementsByBeneficiaireName (token, search) {
    const url = apiBaseUrl + `/conseiller-get-all-remboursements-by-beneficiaire-name/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  getRemboursement (token, remboursement_pk) {
    const url = apiBaseUrl + `/conseiller-get-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  attributeRemboursementsToHimself (token, remboursements_pk_list) {
    const url = apiBaseUrl + `/conseiller-attribute-remboursements-to-himself/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      remboursements: remboursements_pk_list
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  validateRemboursement (token, remboursement_pk, montant,
                         are_frais_deplacement_validated, montant_frais_deplacement, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/conseiller-validate-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData()
    formData.append('montant', montant)
    formData.append('are_frais_deplacement_validated', are_frais_deplacement_validated)
    formData.append('refused_message_frais_deplacement', refused_message_frais_deplacement)
    if (montant_frais_deplacement != '' && montant_frais_deplacement >= 0) {
      formData.append('montant_frais_deplacement', montant_frais_deplacement)
    }
    return axios_instance.put(url, formData, {'headers': headers,})
  }

  modifyRemboursementJustifFile (token, remboursement_pk, justif_file) {
    const url = apiBaseUrl + `/conseiller-modify-remboursement-justif-file/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('justif_file', justif_file);

    return axios_instance.put(url, formData, {'headers': headers,})
  }

  modifyRemboursementJustifFileFraisDeplacement (token, remboursement_pk, justif_file_frais_deplacement) {
    const url = apiBaseUrl + `/conseiller-modify-remboursement-justif-file-frais-deplacement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('justif_file_frais_deplacement', justif_file_frais_deplacement);

    return axios_instance.put(url, formData, {'headers': headers,})
  }

  modifyRemboursementModesGardeNbHoursFile (token, remboursement_pk, creche_nb_hours,
    gad_pajemploi_nb_hours, gad_cesu_nb_hours, gad_structure_nb_hours, pair_nb_hours) {
    const url = apiBaseUrl + `/conseiller-modify-modes-garde-nb-hours/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    var data = {
      'creche_nb_hours': creche_nb_hours,
      'gad_pajemploi_nb_hours': gad_pajemploi_nb_hours,
      'gad_cesu_nb_hours': gad_cesu_nb_hours,
      'gad_structure_nb_hours': gad_structure_nb_hours,
      'pair_nb_hours': pair_nb_hours,
    }


    return axios_instance.put(url, data, {'headers': headers,})
  }

  // -------- GARDE INFORMATIONS --------------
  createGardeCaracteristiques(token, data) {
    const url = `${apiBaseUrl}/garde-caracteristiques/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyGardeCaracteristiques(token, id, data) {
    const url = `${apiBaseUrl}/garde-caracteristiques/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  downloadRemboursementProtectedJustifFile (token, remboursement_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/conseiller-download-remboursement-justif-file/' + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  downloadRemboursementProtectedJustifFileFraisDeplacement (token, remboursement_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/conseiller-download-remboursement-justif-file-frais-deplacement/' + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  refuseRemboursement (token, remboursement_pk, refused_message, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/conseiller-refuse-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'refused_message_frais_deplacement': refused_message_frais_deplacement
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  incompleteRemboursement (token, remboursement_pk, refused_message, refused_message_frais_deplacement) {
    const url = apiBaseUrl + `/conseiller-incomplete-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'refused_message': refused_message,
      'refused_message_frais_deplacement': refused_message_frais_deplacement
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  douteRemboursement (token, remboursement_pk) {
    const url = `${apiBaseUrl}/conseiller-doute-remboursement/${remboursement_pk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }

  miseEnPaiementRemboursement (token, remboursement_pk) {
    const url = apiBaseUrl + `/conseiller-mise-en-paiement-remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  // ------------------ REMBOURSEMENT DOCUMENT ------------
  getAllRemboursementsDocuments (token, remboursement_pk) {
    const url = apiBaseUrl + `/conseiller-get-all-remboursement-documents/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getRemboursementProtectedDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/conseiller-remboursement-document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  validateRemboursementDocument (token, document_pk) {
    const url = apiBaseUrl + `/conseiller-validate-remboursement-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }

  refuseRemboursementDocument (token, document_pk) {
    const url = apiBaseUrl + `/conseiller-refuse-remboursement-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {}
    return axios_instance.put(url, data, {'headers': headers,})
  }

  setNbHoursCCNRemboursementDocument (token, document_pk, nb_hours, ccn) {
    const url = apiBaseUrl + `/conseiller-set-remboursement-document-nb-hours/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'nb_hours': nb_hours,
      'ccn': ccn
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  // PERIODE EMPLOI

  createPeriodesEmploi(token, remboursement_document_pk, periode_emplois) {
    const url = `${apiBaseUrl}/remboursement-document/${remboursement_document_pk}/periodes-emplois/conseiller`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.post(
      url, periode_emplois,
      {
        'headers': headers,
      }
    )
  }

  deletePeriodeEmploi (token, periode_emploi_pk) {
    const url = `${apiBaseUrl}/periodes-emplois/${periode_emploi_pk}/conseiller`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }
}
