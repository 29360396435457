<template>
  <div class="ConseillerDashboard" >
    <formTitleComponent />
      <div class="container-fluid">
        <h1 class="form_div_title text-center">Espace administration conseiller</h1>
        <div class="form_div">
          <div class="form_content">
            <b-tabs v-model="conseillerDashboardTabIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-white" pills>
              <b-tab >
                <template slot="title">
                  <span class="h4">Accords de principe</span>
                </template>
                <accordsConseillerDashboardComponent />
              </b-tab>
              <b-tab>
                <template slot="title">
                  <span class="h4">Demandes de remboursements</span>
                </template>
                <remboursementsConseillerDashboardComponent />
              </b-tab>
            </b-tabs>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import formTitleComponent from '@/components/formTitleComponent'
import accordsConseillerDashboardComponent from '@/components/conseiller/accordsConseillerDashboardComponent'
import remboursementsConseillerDashboardComponent from '@/components/conseiller/remboursementsConseillerDashboardComponent'

export default {
  name: 'ConseillerDashboard',
  components: {
    formTitleComponent,
    accordsConseillerDashboardComponent,
    remboursementsConseillerDashboardComponent
  },
  computed: {
    conseillerDashboardTabIndex: {
      get () {
        return this.$store.state.conseillerDashboardTabIndex
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardTabIndex', value])
      }
    },
  }
}
</script>
