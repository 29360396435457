<template>
  <div class="accordsConseillerDashboardComponent">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isAccordsLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>


    <div class="row">
      <div class="col-12">
        <p> Par défaut, il vous est présenté la liste des demandes d'accords en cours de validation et non attribuées à un conseiller.</p>
      </div>
    </div>

    <b-button
      block variant="outline-danger"
      @click='filterAllAccordsOngoingNotAttributed'>
        <v-icon v-if="conseillerDashboardAllAccordsOngoingNotAttributedFilter" name="lock" class="mr-2 text-danger"/>
        Filtrer les demandes d'accord non attribuées et en cours de validation
    </b-button>

    <div class="row mt-3">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillerDashboardAllAccordsState-group" label="Etat de la demande " label-for="conseillerDashboardAllAccordsState-input">
          <b-form-select
            v-model="conseillerDashboardAllAccordsState"
            id="conseillerDashboardAllAccordsState-input"
            :options='accordStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click="filterAllAccordsByState"
          :disabled="$v.conseillerDashboardAllAccordsState.$invalid">
            <v-icon v-if="conseillerDashboardAllAccordsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer <strong>TOUTES</strong> les demandes d'accord selon l'état
        </b-button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-sm-6 text-center align-self-center">
        <b-form-group id="conseillerDashboardOwnAccordsState-group" label="Etat de la demande " label-for="conseillerDashboardOwnAccordsState-input">
          <b-form-select
            v-model="conseillerDashboardOwnAccordsState"
            id="conseillerDashboardOwnAccordsState-input"
            :options='accordStateArrayForm'>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-6 text-center align-self-center">
        <b-button
          block variant="outline-danger" class="mt-3"
          @click='filterOwnAccordsByState'
          :disabled="$v.conseillerDashboardOwnAccordsState.$invalid">
            <v-icon v-if="conseillerDashboardOwnAccordsByStateFilter" name="lock" class="mr-2 text-danger"/>
            Filtrer <strong>VOS</strong> demandes d'accord selon l'état
        </b-button>
      </div>
    </div>

    <b-form v-on:submit.prevent="getAllAccordsByMuna()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="muna-form-group">
            <b-form-input
              id="muna-form-input"
              type="text" maxlength="11"
              v-model.trim.lazy="conseillerDashboardSearchAccordsMuna"
              @input="$v.conseillerDashboardSearchAccordsMuna.$touch()"
              :state="$v.conseillerDashboardSearchAccordsMuna.$dirty ? !$v.conseillerDashboardSearchAccordsMuna.$error : null"
              placeholder="Muna du bénéficiaire (XXXXXXXX.XX)">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            block variant="outline-danger"
            type="submit"
            :disabled="$v.conseillerDashboardSearchAccordsMuna.$invalid">
              <v-icon v-if="conseillerDashboardAccordsIsMunaFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par muna
          </b-button>
        </div>
      </div>
    </b-form>

    <b-form v-on:submit.prevent="getAllAccordsByBeneficiaireName()">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <b-form-group id="name-form-group">
            <b-form-input
              id="name-form-input"
              type="text" maxlength="150"
              v-model.trim.lazy="conseillerDashboardSearchAccordsName"
              @input="$v.conseillerDashboardSearchAccordsName.$touch()"
              :state="$v.conseillerDashboardSearchAccordsName.$dirty ? !$v.conseillerDashboardSearchAccordsName.$error : null"
              placeholder="Prénom ou nom du bénéficiaire">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 col-12">
          <b-button
            type="submit"
            block variant="outline-danger"
            :disabled="$v.conseillerDashboardSearchAccordsName.$invalid">
              <v-icon v-if="conseillerDashboardIsAccordsNameFilter" name="lock" class="mr-2 text-danger"/>
              Recherche par prénom ou nom du bénéficiaire
          </b-button>
        </div>
      </div>
    </b-form>

    <b-button
      v-if="attributedAccordsList.length > 0"
      block variant="outline-danger"
      @click='attributeAccordsToHimself'>
        Vous attributer les demandes d'accord sélectionnées
    </b-button>

    <b-table
      responsive class="align-middle text-center mt-4"
      headVariant="light"
      :fields="accordsRenderFields"
      :items="accords"
      >
      <!--@row-clicked="beneficiaireRowClicked"-->
      <template v-slot:cell(actions)="data">
        <b-form-checkbox
          @input="checkAccordBox(data.item.id)"
          >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(state)="data">
        {{accordStateRender(data.item.state)}}
      </template>
      <template v-slot:cell(state_changed_date)="data">
        {{$dayjs(data.item.state_changed_date).format('DD/MM/YYYY')}}
      </template>
      <template v-slot:cell(first_ask_validation_date)="data">
        <span v-if="data.item.first_ask_validation_date">
          {{$dayjs(data.item.first_ask_validation_date).format('DD/MM/YYYY')}}
        </span>
      </template>
      <template v-slot:cell(go_to_profile)="data">
        <b-button
          variant="outline-danger" class="ml-2" size="sm"
          @click="$router.push('/conseiller-dashboard/accord/' + data.item['id'])">
          <v-icon name="arrow-right"/>
        </b-button>
      </template>
    </b-table>

    <b-button
      v-if="getAllAccordsOngoingNotAttributedUrl && conseillerDashboardAllAccordsOngoingNotAttributedFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllAccordsOngoingNotAttributed(true)'>
        Voir plus
    </b-button>

    <b-button
      v-if="getAllAccordsByStateUrl && conseillerDashboardAllAccordsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getAllAccordsByState(true)'>
        Voir plus
    </b-button>

    <b-button
      v-if="getOwnAccordsByStateUrl && conseillerDashboardOwnAccordsByStateFilter"
      block variant="outline-dark" class="mt-2"
      @click='getOwnAccordsByState(true)'>
        Voir plus
    </b-button>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import renderMixins from '@/mixins/renderMixins'
import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { isMuna } from '@/validators/validators'

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'accordsConseillerDashboardComponent',
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      accords: [],
      accordsRenderFields: [
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
        { key: "beneficiaire_muna", label: "Muna", tdClass: 'ui-helper-center', sortable: true},
        { key: "beneficiaire_full_name", label: "Nom du bénéficiaire", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center' },
        { key: "first_ask_validation_date", label: "Date de dépôt", tdClass: 'ui-helper-center', sortable: true},
        { key: "conseiller_full_name", label: "Conseiller attribué", tdClass: 'minWidthTd ui-helper-center', sortable: true },
        { key: "go_to_profile", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isAccordsLoading: true,

      // ------------ GET ALL ONGOING ACCORD TO ATTRIBUTE -----------
      baseGetAllAccordsOngoingNotAttributedlUrl: apiBaseUrl + `/conseiller-get-all-accords-ongoing-not-attributed/`,
      getAllAccordsOngoingNotAttributedUrl: apiBaseUrl + `/conseiller-get-all-accords-ongoing-not-attributed/`,

      // ------------ GET ALL ACCORD BY STATE -----------
      baseGetAllAccordsByStateUrlUrl: apiBaseUrl + `/conseiller-get-all-accords-by-state/`,
      getAllAccordsByStateUrl: apiBaseUrl + `/conseiller-get-all-accords-by-state/`,

      // ------------ GET ALL OWN ACCORD BY STATE -----------
      baseGetOwnAccordsByStateUrl: apiBaseUrl + `/conseiller-get-all-own-accords-by-state/`,
      getOwnAccordsByStateUrl: apiBaseUrl + `/conseiller-get-all-own-accords-by-state/`,

      accordStateArrayForm: [
        { text: 'Créé par le bénéficiaire', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Incomplet', value: '3INCOMPLET' },
        { text: "Incomplet : avis d'imposition non suffisant", value: '3IMPOTINCOMPLET' },
        { text: 'Refusé par le conseiller', value: '4CREFUSED' },
        { text: 'Validé par le conseiller', value: '5CVALIDATED' },
        { text: 'Refusé par le manager', value: '6MREFUSED' },
        { text: 'Validé par le manager', value: '7MVALIDATED' },
      ],

      // ---------- ATTRIBUTE ACCORDS -----------------------
      attributedAccordsList: [],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    conseillerDashboardAllAccordsOngoingNotAttributedFilter: {
      get () {
        return this.$store.state.conseillerDashboardAllAccordsOngoingNotAttributedFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllAccordsOngoingNotAttributedFilter', value])
      }
    },
    conseillerDashboardAllAccordsState: {
      get () {
        return this.$store.state.conseillerDashboardAllAccordsState
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllAccordsState', value])
      }
    },
    conseillerDashboardAllAccordsByStateFilter: {
      get () {
        return this.$store.state.conseillerDashboardAllAccordsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAllAccordsByStateFilter', value])
      }
    },
    conseillerDashboardOwnAccordsState: {
      get () {
        return this.$store.state.conseillerDashboardOwnAccordsState
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardOwnAccordsState', value])
      }
    },
    conseillerDashboardOwnAccordsByStateFilter: {
      get () {
        return this.$store.state.conseillerDashboardOwnAccordsByStateFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardOwnAccordsByStateFilter', value])
      }
    },
    conseillerDashboardSearchAccordsMuna: {
      get () {
        return this.$store.state.conseillerDashboardSearchAccordsMuna
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardSearchAccordsMuna', value])
      }
    },
    conseillerDashboardAccordsIsMunaFilter: {
      get () {
        return this.$store.state.conseillerDashboardAccordsIsMunaFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardAccordsIsMunaFilter', value])
      }
    },
    conseillerDashboardSearchAccordsName: {
      get () {
        return this.$store.state.conseillerDashboardSearchAccordsName
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardSearchAccordsName', value])
      }
    },
    conseillerDashboardIsAccordsNameFilter: {
      get () {
        return this.$store.state.conseillerDashboardIsAccordsNameFilter
      },
      set (value) {
        this.$store.commit('set', ['conseillerDashboardIsAccordsNameFilter', value])
      }
    },
  },
  created: function() {
    if (this.conseillerDashboardAllAccordsByStateFilter && this.conseillerDashboardAllAccordsState) {
      this.getAllAccordsByState()
    }
    else if (this.conseillerDashboardOwnAccordsByStateFilter && this.conseillerDashboardOwnAccordsState) {
      this.getOwnAccordsByState()
    }
    else if (this.conseillerDashboardAccordsIsMunaFilter && isMuna(this.conseillerDashboardSearchAccordsMuna) && this.conseillerDashboardSearchAccordsMuna != null && this.conseillerDashboardSearchAccordsMuna != '') {
      this.getAllAccordsByMuna()
    }
    else if (this.conseillerDashboardIsAccordsNameFilter && this.conseillerDashboardSearchAccordsName != null && this.conseillerDashboardSearchAccordsName != '') {
      this.getAllAccordsByBeneficiaireName()
    }
    else {
      this.getAllAccordsOngoingNotAttributed()
    }
  },
  validations: {
    conseillerDashboardAllAccordsState: {
      required
    },
    conseillerDashboardOwnAccordsState: {
      required
    },
    conseillerDashboardSearchAccordsMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    conseillerDashboardSearchAccordsName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
    }
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllAccordsOngoingNotAttributed (isNext) {
      this.isAccordsLoading = true
      var url = this.getAllAccordsOngoingNotAttributedUrl
      if (isNext) {
        url = this.getAllAccordsOngoingNotAttributedUrl
      }
      apiConseillerConnected.getAllAccordsOngoingNotAttributed(this.token, url)
      .then((result) => {
        this.accords = this.accords.concat(result.data.results)
        this.getAllAccordsOngoingNotAttributedUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllAccordsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.conseillerDashboardAllAccordsOngoingNotAttributedFilter = true
        this.conseillerDashboardAllAccordsByStateFilter = false
        this.conseillerDashboardOwnAccordsByStateFilter = false
        this.conseillerDashboardAccordsIsMunaFilter = false
        this.conseillerDashboardIsAccordsNameFilter = false
      })
    },

    getAllAccordsByState (isNext) {
      this.isAccordsLoading = true
      var url = this.getAllAccordsByStateUrl + this.conseillerDashboardAllAccordsState
      if (isNext) {
        url = this.getAllAccordsByStateUrl
      }
      apiConseillerConnected.getAllAccordsByState(this.token, url, null)
      .then((result) => {
        this.accords = this.accords.concat(result.data.results)
        this.getAllAccordsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllAccordsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.conseillerDashboardAllAccordsOngoingNotAttributedFilter =false
        this.conseillerDashboardAllAccordsByStateFilter = true
        this.conseillerDashboardOwnAccordsByStateFilter = false
        this.conseillerDashboardAccordsIsMunaFilter = false
        this.conseillerDashboardIsAccordsNameFilter = false
      })
    },

    getOwnAccordsByState (isNext) {
      this.isAccordsLoading = true
      var url = this.getOwnAccordsByStateUrl + this.conseillerDashboardOwnAccordsState
      if (isNext) {
        url = this.getOwnAccordsByStateUrl
      }
      apiConseillerConnected.getOwnAccordsByState(this.token, url)
      .then((result) => {
        this.accords = this.accords.concat(result.data.results)
        this.getOwnAccordsByStateUrl = result.data.next
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getOwnAccordsByState API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.conseillerDashboardAllAccordsOngoingNotAttributedFilter =false
        this.conseillerDashboardAllAccordsByStateFilter = false
        this.conseillerDashboardOwnAccordsByStateFilter = true
        this.conseillerDashboardAccordsIsMunaFilter = false
        this.conseillerDashboardIsAccordsNameFilter = false
      })
    },

    getAllAccordsByMuna () {
      this.isAccordsLoading = true
      this.accords = []
      apiConseillerConnected.getAllAccordsByMuna(this.token, this.conseillerDashboardSearchAccordsMuna)
      .then((result) => {
        this.accords = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllAccordsByMuna API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.conseillerDashboardAllAccordsOngoingNotAttributedFilter =false
        this.conseillerDashboardAllAccordsByStateFilter = false
        this.conseillerDashboardOwnAccordsByStateFilter = false
        this.conseillerDashboardAccordsIsMunaFilter = true
        this.conseillerDashboardIsAccordsNameFilter = false
      })
    },

    getAllAccordsByBeneficiaireName () {
      this.isAccordsLoading = true
      this.accords = []
      apiConseillerConnected.getAllAccordsByBeneficiaireName(this.token, this.conseillerDashboardSearchAccordsName)
      .then((result) => {
        this.accords = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard getAllAccordsByBeneficiaireName API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
        this.conseillerDashboardAllAccordsOngoingNotAttributedFilter =false
        this.conseillerDashboardAllAccordsByStateFilter = false
        this.conseillerDashboardOwnAccordsByStateFilter = false
        this.conseillerDashboardAccordsIsMunaFilter = false
        this.conseillerDashboardIsAccordsNameFilter = true
      })
    },

    filterAllAccordsOngoingNotAttributed () {
      this.getAllAccordsOngoingNotAttributedUrl = this.baseGetAllAccordsOngoingNotAttributedlUrl
      this.accords = []
      this.getAllAccordsOngoingNotAttributed(false)
    },

    filterAllAccordsByState () {
      this.getAllAccordsByStateUrl = this.baseGetAllAccordsByStateUrlUrl
      this.accords = []
      this.getAllAccordsByState(false)
    },

    filterOwnAccordsByState () {
      this.getOwnAccordsByStateUrl = this.baseGetOwnAccordsByStateUrl
      this.accords = []
      this.getOwnAccordsByState(false)
    },

    // ---------------- ATTRIBUTE CONSEILLER ---------------
    checkAccordBox (accordId) {
      const index = this.attributedAccordsList.indexOf(accordId)
      if (index === -1){
        this.attributedAccordsList.push(accordId)
      }
      else {
        this.attributedAccordsList.splice(index, 1)
      }
    },
    attributeAccordsToHimself () {
      this.isAccordsLoading = true
      apiConseillerConnected.attributeAccordsToHimself(this.token, this.attributedAccordsList)
      .then(() => {
        this.attributedAccordsList = []
        if (this.conseillerDashboardAllAccordsByStateFilter && this.conseillerDashboardAllAccordsState) {
          this.filterAllAccordsByState()
        }
        if (this.conseillerDashboardOwnAccordsByStateFilter && this.conseillerDashboardOwnAccordsState) {
          this.filterOwnAccordsByState()
        }
        else if (this.conseillerDashboardAccordsIsMunaFilter && isMuna(this.conseillerDashboardSearchAccordsMuna) && this.conseillerDashboardSearchAccordsMuna != null && this.conseillerDashboardSearchAccordsMuna != '') {
          this.getAllAccordsByMuna()
        }
        else if (this.conseillerDashboardIsAccordsNameFilter && this.conseillerDashboardSearchAccordsName != null && this.conseillerDashboardSearchAccordsName != '') {
          this.getAllAccordsByBeneficiaireName()
        }
        else {
          this.filterAllAccordsOngoingNotAttributed()
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerDashboard attributeAccordsToHimself API Error : ' + String(error))
      })
      .finally(() => {
        this.isAccordsLoading = false
      })
    },
  }
}
</script>
<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
